import 'owl.carousel';

const mainSlider = () => {
    const slider = $('.main-slider');

    if (!slider.hasClass('owl-carousel')) {
        slider.addClass('owl-carousel');
    }

    slider.owlCarousel({
        nav: true,
        navText : ['<i class="fa fa-chevron-left"></i>','<i class="fa fa-chevron-right"></i>'],
        loop: $('.main-slider').children().length > 1,
        items: 1,
        autoplay: true,
        smartSpeed: 500,
        addClassActive: true,
        onChanged: function (evt) {
            setTimeout(function(){
                $('.main-slider').find('.active .overlay').addClass('animated')
                $('.main-slider').find('.active').siblings().find('.overlay').removeClass('animated')
            }, 100);
        }
    });
}

const thumbSlider = () => {
    const slider = $('.thumbs-slider');

    if (!slider.hasClass('owl-carousel')) {
        slider.addClass('owl-carousel');
    }

    slider.owlCarousel({
        navigation: false,
        dots: true,
        loop: false,
        items: 1,
        autoplay: true,
    });
}

$(document).ready(() => {
    mainSlider();
    thumbSlider();

    if ($('body').hasClass('content-page') && $g < 10) {
        $('#header').addClass('no-slide');
        $('body').addClass('body-no-slide');
    }


    $(".latest-properties-slider").owlCarousel({
        nav: true,
        items: 3,
        loop: false,
        dots: false,
        margin: 60,
        autoplay: false,
        smartSpeed: 1400,
        addClassActive: true,
        animateOut: 'fadeOut',
        animateIn: 'fadeIn',
        loop: $('.slides').children().length > 1,
        navText: [
      '<i class="fa fa-angle-left" aria-hidden="true"></i>',
      '<i class="fa fa-angle-right" aria-hidden="true"></i>'
      ],
        responsive:{
        0:{
            items:1,
            dots: true,
            loop: true,
        },
        700:{
            items:2,
            dots: true,
            loop: true,
        },
        1000:{
            items:3
         }
        }
    });
    $(".gallery-main-properties").owlCarousel({
        false: true,
        items: 1,
        loop: false,
        dots: true,
        margin: 60,
        autoplay: false,
        smartSpeed: 1400,
        addClassActive: true,
        animateOut: 'fadeOut',
        animateIn: 'fadeIn',
        loop: $('.slides').children().length > 1,
        navText: [
      '<i class="fa fa-angle-left" aria-hidden="true"></i>',
      '<i class="fa fa-angle-right" aria-hidden="true"></i>'
      ],
    });

    $(".review-slider").owlCarousel({
        false: true,
        items: 3,
        loop: false,
        dots: true,
        margin: 60,
        autoplay: false,
        smartSpeed: 1400,
        addClassActive: true,
        animateOut: 'fadeOut',
        loop: $('.slides').children().length > 1,
        responsive:{
        0:{
            items:1
        },
        700:{
            items:2
        },
        1000:{
            items:3
         }
        }
    });

  var sync1 = $("#sync1");
  var sync2 = $("#sync2");
  var slidesPerPage = 4; //globaly define number of elements per page
  var syncedSecondary = true;

  sync1.owlCarousel({
    items : 1,
    slideSpeed : 2000,
    nav: true,
    autoplay: false,
    dots: true,
    loop: true,
    responsiveRefreshRate : 200,
    navText: [
    '<i class="fa fa-angle-left" aria-hidden="true"></i>',
    '<i class="fa fa-angle-right" aria-hidden="true"></i>'
    ],
  }).on('changed.owl.carousel', syncPosition);

  sync2
    .on('initialized.owl.carousel', function () {
      sync2.find(".owl-item").eq(0).addClass("current");
    })
    .owlCarousel({
    items : slidesPerPage,
    dots: true,
    nav: true,
    smartSpeed: 200,
    margin: 10,
    slideSpeed : 500,
    slideBy: slidesPerPage, //alternatively you can slide by 1, this way the active slide will stick to the first item in the second carousel
    responsiveRefreshRate : 100
  }).on('changed.owl.carousel', syncPosition2);

  function syncPosition(el) {
    //if you set loop to false, you have to restore this next line
    //var current = el.item.index;

    //if you disable loop you have to comment this block
    var count = el.item.count-1;
    var current = Math.round(el.item.index - (el.item.count/2) - .5);

    if(current < 0) {
      current = count;
    }
    if(current > count) {
      current = 0;
    }

    //end block

    sync2
      .find(".owl-item")
      .removeClass("current")
      .eq(current)
      .addClass("current");
    var onscreen = sync2.find('.owl-item.active').length - 1;
    var start = sync2.find('.owl-item.active').first().index();
    var end = sync2.find('.owl-item.active').last().index();

    if (current > end) {
      sync2.data('owl.carousel').to(current, 100, true);
    }
    if (current < start) {
      sync2.data('owl.carousel').to(current - onscreen, 100, true);
    }
  }

  function syncPosition2(el) {
    if(syncedSecondary) {
      var number = el.item.index;
      sync1.data('owl.carousel').to(number, 100, true);
    }
  }

  sync2.on("click", ".owl-item", function(e){
    e.preventDefault();
    var number = $(this).index();
    sync1.data('owl.carousel').to(number, 300, true);
  });


});
