const headerClass = () => {

    const scroll = $(window).scrollTop();
    if (scroll <= 100) {
        $('header').removeClass('header-scrolled');
        $('.right-content-propery').removeClass('fixed-scrolled');
    } else {
        $('header').addClass('header-scrolled');
        $('.right-content-propery').addClass('fixed-scrolled');

    }
};

$(window).resize(headerClass);
$(window).scroll(headerClass);

headerClass();


const headerContentClass = () => {

    const scroll = $(window).scrollTop();
    if (scroll <= 100) {
        $('header-content').removeClass('header-scrolled');
        $('.right-content-propery').removeClass('fixed-scrolled');
    } else {
        $('header-content').addClass('header-scrolled');
        $('.right-content-propery').addClass('fixed-scrolled');        
    }
};

$(window).resize(headerContentClass);
$(window).scroll(headerContentClass);

headerContentClass();
